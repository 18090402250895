import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    title: 'Departures',
    disclaimer: 'We can not guarantee correct display of departure times. Please always check with the official schedule from Deutsche Bahn',
    tableHead: {
      time: 'Time',
      destination: 'To',
      stops: 'Via',
      platform: 'Platform',
    },
    messages: {
      changedPlatform: 'Today at platform ',
      splitStop: '+++ Wings split in',
      diffNoStop: '+++ Not stopping in:',
      diffNewStop: '+++ Also stopping in:',
      delay: 'approx. %s minutes later',
    },
    status: {
      cancelled: 'Cancelled',
      added: 'Added',
      planned: 'Planned',
    },
  },
  de: {
    title: 'Abfahrt',
    disclaimer: 'Wir übernehmen keine Haftung für evtl. Anzeigefehler. Bitte prüfen Sie stets die offiziellen Fahrpläne der Deutschen Bahn.',
    tableHead: {
      time: 'Uhrzeit',
      destination: 'Nach',
      stops: 'Über',
      platform: 'Gleis',
    },
    messages: {
      changedPlatform: 'Heute Gleis',
      splitStop: '+++ Zugteilung in',
      diffNoStop: '+++ Hält nicht in:',
      diffNewStop: '+++ Hält auch in:',
      delay: 'ca. {m} Min. später',
    },
    status: {
      c: 'Fällt aus',
      a: 'Hinzugefügt',
      p: 'Geplant',
    },
  },
};

const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
