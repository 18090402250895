<template>
  <li class="departure" :data-id="depData.id">
    <div class="time">
      {{ depData.plannedTime }}
      <div
        class="changed"
        v-if="depData.changedTime !== depData.plannedTime"
      >
        {{ depData.changedTime }}
      </div>
    </div>

    <div :data-has-wing="!!depData.wings"
         :data-is-wing="depData.isWing"
         class="destination"
    >
      {{ depData.stops.slice(-1)[0] }}
      <div class="train">{{ depData.trainType }} {{ depData.lineNo }}</div>
    </div>
    <Journey
      v-bind:changed-time="depData.dbChangedDatetime"
      v-bind:messages="depData.messages"
      v-bind:id="depData.id"
      v-bind:stops="depData.stops"
    />
    <div class="platform">{{ depData.platform }}</div>
  </li>
</template>

<script>
import DepartureJourney from '@/components/DepartureJourney.vue';

export default {
  name: 'Departure',
  components: { Journey: DepartureJourney },
  props: { depData: Object },
};
</script>

<style scoped lang="scss">
  li {
    padding: 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px dashed #e1c08d;
    align-items: center;

    > div {
      flex: 1;
      text-align: left;
      padding-right: 1em;
      margin: 0.5em;
    }

    .destination {
      flex:  2;
      word-break: break-word;
      position: relative;
    }

    .platform,
    .time {
      text-align: center;
    }

    .changed {
      color: darken(#e1c08d, 10%);
    }

    .train {
      font-size: 0.8em;
    }

    [data-has-wing="true"]:after {
      content: '⎣';
      transform: rotate(180deg);
      float: right;
      position: absolute;
      margin-left: 1em;
      right: 0;
      top: 0;
      font-size: 1.2em;
    }

    [data-is-wing="true"]:after {
      content: '⎦';
      float: right;
      position: absolute;
      margin-left: 1em;
      right: 0;
      font-size: 1.2em;
      bottom: 0;
    }
  }
</style>
