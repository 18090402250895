<template>
  <div class="journey">
    <div class="wrapper">
      <Stops v-bind:stops="stops" />
      <div class="messages">
        <div
          v-for="(message, index) in messages"
          v-bind:key="id + `-${index}`"
          v-bind:message="message"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartureJourneyStops from '@/components/DepartureJourneyStops.vue';

export default {
  name: 'DepartureJourney',
  components: {
    Stops: DepartureJourneyStops,
  },
  props: {
    id: String,
    stops: Array,
    messages: Array,
    changedTime: String,
  },
};
</script>

<style scoped lang="scss">
  .journey {
    display: inline;
    flex: 5;
    overflow: hidden;

    .wrapper {
      white-space: nowrap;
    }
    .messages {
      word-break: break-word;
      color: darken(#e1c08d, 10%);
      font-size: 0.7em;
    }
  }
</style>
