<template>
  <div class="schedule">
    <h2 v-html="currentTime"></h2>
    <span class="disclaimer">{{ $t('disclaimer') }}</span>
    <ul>
      <li class="table-head">
        <div class="caption time">{{ $t('tableHead.time')}}</div>
        <div class="caption destination">{{ $t('tableHead.destination')}}</div>
        <div class="caption next">{{ $t('tableHead.stops')}}</div>
        <div class="caption platform">{{ $t('tableHead.platform')}}</div>
      </li>
      <Departure v-for="depData in departures"
                 v-bind:depData="depData"
                 v-bind:key="depData.id">
      </Departure>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import i18n from '@/plugins/i18n';
import Departure from '@/components/Departure.vue';

const token = process.env.NODE_ENV === 'DEV' ? '9b237768264ef9368d08b783cb106196' : '61665aa1d2ff4cd8952d053ede8ee7e1';

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
  },
};

// TODO move to store
const wings = [];

// TODO move to helper?
// TODO Documentation
const getDateTime = (bTimestamp) => {
  const year = parseInt(`20${bTimestamp.slice(0, 2)}`, 10);
  const month = parseInt(bTimestamp.slice(2, 4), 10) - 1;
  const day = parseInt(bTimestamp.slice(4, 6), 10);
  const hours = parseInt(bTimestamp.slice(6, 8), 10);
  const minute = parseInt(bTimestamp.slice(8, 10), 10);

  return new Date(year, month, day, hours, minute);
};

const getTime = (bTimestamp) => {
  const date = getDateTime(bTimestamp);
  return `${date.getHours()}:${((date.getMinutes() < 10) ? '0' : '') + date.getMinutes()}`;
};

export default {
  name: 'Timetable',
  components: {
    Departure,
  },
  props: {
    station: String,
    eva: String,
  },
  data() {
    return {
      currentTime: null,
      changes: null,
      baseData: [],
      departures: [],
    };
  },
  computed: {
    scheduleHours() {
      const date = new Date();
      const year = date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear().toString();
      const month = date.getMonth() + 1 < 10 ? `0${(1 + date.getMonth())}` : (1 + date.getMonth()).toString();
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
      const requestDate = year.slice(2, 4) + month + day;

      let offsetHours = date.getHours();
      if (offsetHours === 23) {
        offsetHours = -1;
      }

      // TODO prepare real date modifications?
      let i;
      let hour;
      const offset = 2;
      const schedules = [];

      for (i = -1; i <= offset; i += 1) {
        hour = offsetHours + i;
        if (hour < 10) {
          hour = `0${hour}`;
        }

        schedules.push({
          date: requestDate,
          hour,
        });
      }

      return schedules;
    },
  },
  methods: {
    processData() {
      const that = this;
      const base = _.clone(that.baseData);

      base.forEach((infoEl, index) => {
        const messages = [];

        if (that.changes && that.changes.length) {
          Object.keys(that.changes).forEach((key) => {
            const el = that.changes.item(key);

            if (typeof el.parentNode !== 'undefined' && infoEl.id === el.parentNode.id) {
              const changedPlatform = el.getAttribute('cp') || '';
              let changedTime = '';
              const dbChangedDatetime = el.getAttribute('ct') || '';
              const changedStatus = el.getAttribute('cs') || '';
              let changedPath = el.getAttribute('cpth') || [];

              if (changedStatus) {
                messages.push(i18n.t(`status.${changedStatus}`));
              }

              if (changedPlatform) {
                messages.push(`${i18n.t('messages.changedPlatform')} ${changedPlatform}`);
              }

              if (dbChangedDatetime) {
                changedTime = getTime(dbChangedDatetime);
                const m = (getDateTime(dbChangedDatetime).getTime()
                  - getDateTime(base[index].dbDatetime).getTime()) / (1000 * 60);
                if (m >= 5) {
                  messages.push(`${i18n.t('messages.delay', { m })}`);
                }
              }

              if (changedPath.length) {
                changedPath = changedPath.split('|');
                const diffNoStop = _.difference(base[index].stops, changedPath).join(', ');
                if (diffNoStop) {
                  messages.push(`${i18n.t('messages.diffNoStop')} ${diffNoStop}`);
                }
                const diffNewStop = _.difference(changedPath, base[index].stops).join(', ');
                if (diffNewStop) {
                  messages.push(`${i18n.t('messages.diffNewStop')} ${diffNewStop}`);
                }
              }

              base[index] = {
                ...base[index],
                platform: changedPlatform || base[index].platform,
                stops: changedPath.length ? changedPath : base[index].stops,
                hidden: el.getAttribute('hi') || false,
                dbChangedDatetime,
                changedTime,
              };
            }
          });
        }
        // TODO handle multiple
        const wingsPath = that.getWingStops(base[index].wings, base);

        if (wingsPath) {
          let splitStop = null;
          wingsPath.forEach((stop, i) => {
            if (stop === base[index].stops[i]) {
              splitStop = stop;
            }
          });

          if (splitStop && (splitStop !== wingsPath[wingsPath.length - 1])) {
            messages.push(`${i18n.t('messages.splitStop')} ${splitStop}`);
          }
        }

        base[index] = {
          ...base[index],
          messages,
          isWing: !!wings.filter((wingId) => ((base[index].id.indexOf(wingId) !== -1))).length,
        };
      });

      this.departures = base.filter((infoEl) => (
        ((getDateTime((infoEl.dbChangedDatetime || '') || infoEl.dbDatetime) > new Date())
            || infoEl.hidden
        )))
        .sort((first, next) => ((first.dbDatetime > next.dbDatetime) ? 1 : -1))
        .sort((first, next) => {
          if (first.dbDatetime === next.dbDatetime
            && (!!first.wings || !!next.wings)
            && (first.id.indexOf(next.wings) !== -1 || next.id.indexOf(first.wings) !== -1)
          ) {
            if (first.lineNo < next.lineNo) {
              return 1;
            }
            if (first.lineNo > next.lineNo) {
              return -1;
            }
          }
          return 0;
        });
    },
    getWingStops(id, haystack = []) {
      if (id) {
        return _.result(
          _.find(haystack, (obj) => (obj.id.indexOf(id) !== -1)),
          'stops',
        );
      }
      return null;
    },
    requestChanges() {
      const that = this;
      const url = `https://api.deutschebahn.com/timetables/v1/fchg/${this.eva}`;
      axios
        .get(url, config)
        .then((response) => {
          const parser = new DOMParser();
          const data = response.data.replace(/\s{4,}/g, '').replace(/\n/g, '');
          that.changes = parser.parseFromString(data, 'text/xml').childNodes[0].getElementsByTagName('dp');
        })
        .finally(that.processData)
        .catch((e) => console.log(e));
    },
    requestTimetableData() {
      const data = [];
      const that = this;
      const requests = [];

      this.scheduleHours.forEach((val, index) => {
        requests.push(
          axios.get(
            `https://api.deutschebahn.com/timetables/v1/plan/${this.eva}/${that.scheduleHours[index].date}/${that.scheduleHours[index].hour}`,
            config,
          ),
        );
      });

      axios
        .all(requests)
        // eslint-disable-next-line
        .then(axios.spread(function () {
          const parser = new DOMParser();
          const xmlNodes = [];
          // eslint-disable-next-line
          const args = arguments;
          Object.keys(args).forEach((el) => {
            // get all relevant station nodes from the xml responses
            xmlNodes.push(...parser.parseFromString(args[el].data, 'text/xml').childNodes[0].childNodes);
          });
          xmlNodes.forEach((el) => {
            /*
            * s(stop) : TimetableStop (id attribute)
            * ar: arrival -> data in attributes
            * dp: departure -> data in attributes
            * m: message
            * hd
            *
            * tl.c category "ICE","RB"
            * tl.n trainnumber
            * dp.pp planned platform
            * dp.cp changed platform
            * dp.pt planned time
            * dp.ct changed time
            * dp.ppth planned path
            * dp.pde planned destination
            * dp.cde changed destination
            */
            let departure = el.getElementsByTagName('dp');
            if (!departure.length) { return; }
            // eslint-disable-next-line prefer-destructuring
            departure = departure[0];
            if (departure.getAttribute('wings')) {
              // TODO handle multiple wings in attr (multiple like this: 123|2123|3345)
              wings.push(departure.getAttribute('wings'));
            }

            data.push({
              id: el.id,
              wings: departure.getAttribute('wings'),
              dbDatetime: departure.getAttribute('pt'),
              plannedTime: getTime(departure.getAttribute('pt')),
              destination: departure.getAttribute('pde'),
              platform: departure.getAttribute('pp'),
              stops: departure.getAttribute('ppth').split('|'),
              trainType: el.childNodes[0].getAttribute('c'),
              lineNo: el.childNodes[0].getAttribute('f') === 'F' ? el.childNodes[0].getAttribute('n') : departure.getAttribute('l'),
            });
          });
          that.baseData = data;
        }))
        .finally(that.requestChanges)
        .catch(console.log);
    },
  },
  watch: {
    currentTime: [
      'requestTimetableData',
    ],
  },
  mounted() {
    this.currentTime = `${new Date().getHours()}<span>:</span>${(new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes()}`;

    setInterval(() => {
      this.currentTime = `${new Date().getHours()}<span>:</span>${(new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes()}`;
    }, 30 * 100);
  },
};
</script>

<style scoped lang="scss">
ul {
  padding: 0;
  margin: .1em auto;
}

h2 {
  margin: 0.5em 0 0.2em;
  color: #e1c08d;
  ::v-deep span {
    animation: blinker 1s linear infinite;
  }
}

@keyframes blinker {
  50% {
    opacity: .1;
  }
}

.disclaimer {
  font-size: 0.6em;
  font-weight: lighter;
}

.schedule li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 0;

  &:first-child {
    color: #333;
    background-color: #e1c08d;
    background-image: linear-gradient(rgba(0, 0, 0 ,0), lighten(#e1c08d, 15%), rgba(0,0,0,0));
  }

  .caption {
    font-weight: bold;
    font-size: 1.2em;
    padding-right: 0.8em;
    margin: 0.4em;
    overflow: hidden;
    flex: 1;
    text-align: left;
  }
  .destination {
    flex: 2;
  }

  .platform,
  .time {
    text-align: center;
  }

  .next {
    flex: 5;
  }
}
</style>
