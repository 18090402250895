<template>
  <div id="app">
    <div id="header">
      <img alt="Vue logo" src="./assets/logo.png">
      <h1>{{ $t('title')}} {{ station }}</h1>
    </div>
    <Timetable
      v-bind:station="station"
      v-bind:eva="eva"
    />
  </div>
</template>

<script>
import TheTimetable from '@/components/TheTimetable.vue';

// TODO fetch station info first
export default {
  name: 'App',
  data() {
    return {
      eva: '8001312',
      station: 'Butzbach',
    };
  },
  components: {
    Timetable: TheTimetable,
  },
};
</script>

<style lang="scss">
body {
  background-color: floralwhite;
  color: #333;
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}

#app {
  font-family: "Courier New", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  border-color: white;
  font-weight: bolder;

  img {
    max-height: 3em;
    height: auto;
    width: auto;
  }

  #header {
    background: #333;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;

    div, img {
      margin: 0 2em;
    }
  }
}
</style>
