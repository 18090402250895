<template>
  <span v-bind:style="{ marginLeft: `${left}px` }">{{ getStops }}</span>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'DepartureJourneyStops',
  props: {
    stops: Array,
  },
  computed: {
    getStops() {
      const s = _.clone(this.stops);
      s.pop();
      return s.join(', ');
    },
  },
  data() {
    return {
      left: 0,
    };
  },
  mounted() {
    const initialPos = this.$el.parentElement.parentElement.clientWidth;
    if (this.$el.clientWidth < initialPos) {
      return;
    }
    this.left = initialPos;
    setInterval(() => {
      this.left += -1;
      if (this.left < -this.$el.clientWidth) {
        this.left = initialPos - 1;
      }
    }, 10 * 2);
  },
};
</script>

<style scoped>
span {
  display: inline-block;
}
</style>
